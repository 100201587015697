import BaseComponent from "@/components/base/el/BaseComponent";
// import BaseResize from "@/components/base/el/BaseResize";

export default {
    name: "BaseImg",
    mixins: [
      // BaseResize,
      BaseComponent
    ],
    render(createElement) {
      let data = this.data_object;
      // let children  = this.get_children_resize(createElement)
      // if (this.$store.state.edit_mode) {
      //     return createElement(tag, data, [...children, createElement('img',{attrs: {src: this.src, class: 'mw-100 w-100'}}) ])
      // } else {
          return createElement('img', data)
      // }
    },
    data() {
        return {
            window_width: window.innerWidth,
            is_img: true,
        }
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.window_width = window.innerWidth
        })
    },
    computed: {
      desktop_transform() {
          return this.component_data.attr.style.transform_data.desktop || 'matrix(1, 0, 0, 1, 0, 0)';
      },
      tablet_transform() {
          return this.component_data.attr.style.transform_data.tablet || 'matrix(1, 0, 0, 1, 0, 0)';
      },
      mobile_transform() {
          return this.component_data.attr.style.transform_data.mobile || 'matrix(1, 0, 0, 1, 0, 0)';
      },
      // mobile_flex() {
      //     return this.component_data.attr.style.flex_data.mobile;
      // },
      // tablet_flex() {
      //   return this.component_data.attr.style.flex_data.tablet;
      // },
      // desktop_flex() {
      //   return this.component_data.attr.style.flex_data.desktop;
      // },
      // desktop_size() {
      //   return this.component_data.attr.style.size.desktop
      // },
      // tablet_size() {
      //   return this.component_data.attr.style.size.tablet
      // },
      // mobile_size() {
      //   return this.component_data.attr.style.size.mobile
      // },
      // padding_data_top() {
      //   return (reponsive) => {
      //     if (!this.component_data.attr.style.padding_data || !this.component_data.attr.style.padding_data[reponsive]) return null
      //     return this.component_data.attr.style.padding_data[reponsive].top;
      //   }
      // },
      // padding_data_right() {
      //   return (reponsive) => {
      //     if (!this.component_data.attr.style.padding_data || !this.component_data.attr.style.padding_data[reponsive]) return null
      //     return this.component_data.attr.style.padding_data[reponsive].right;
      //   }
      // },
      // padding_data_bottom() {
      //   return (reponsive) => {
      //     if (!this.component_data.attr.style.padding_data || !this.component_data.attr.style.padding_data[reponsive]) return null
      //     return this.component_data.attr.style.padding_data[reponsive].bottom;
      //   }
      // },
      // padding_data_left() {
      //   return (reponsive) => {
      //     if (!this.component_data.attr.style.padding_data || !this.component_data.attr.style.padding_data[reponsive]) return null
      //     return this.component_data.attr.style.padding_data[reponsive].left;
      //   }
      // },
      data_object() {
          let class_array = [{"deepsel-focus": this.is_editing}]
          if (this.component_data.attr && this.component_data.attr.class) {
              let class_value

              if (Array.isArray(this.component_data.attr.class)) {
                  class_value = this.component_data.attr.class.join(" ")
              } else class_value = this.component_data.attr.class

              class_array.push(class_value)
          }
          return {
              class: class_array,
              attrs: {
                  draggable: false,
              },
              style: this.styling,
              on: {
                  mouseenter: this.mouse_enter,
                  mousedown: this.mouse_down,
              },
          };
      },
      src() {
          return this.component_data.attr.src
      },
      styling() {
          if (!this.component_data.attr.style) return {}
          let styling = JSON.parse(JSON.stringify(this.component_data.attr.style))
          delete styling.transform_data
          let transform
          // ,flex,size,padding_top,padding_right,padding_bottom,padding_left
          if (this.window_width < 600){
              transform = this.mobile_transform
              // flex = this.mobile_flex
              // size = this.mobile_size
              // padding_top = this.padding_data_top('mobile')
              // padding_right = this.padding_data_right('mobile')
              // padding_bottom = this.padding_data_bottom('mobile')
              // padding_left = this.padding_data_left('mobile')

          } else if (this.window_width < 992) {
            transform = this.tablet_transform
            // padding_top = this.padding_data_top('tablet')
            // padding_right = this.padding_data_right('tablet')
            // padding_bottom = this.padding_data_bottom('tablet')
            // padding_left = this.padding_data_left('tablet')
            // flex = this.tablet_flex
            // size = this.tablet_size
          } else {
            transform = this.desktop_transform
            // padding_top = this.padding_data_top('desktop')
            // padding_right = this.padding_data_right('desktop')
            // padding_bottom = this.padding_data_bottom('desktop')
            // padding_left = this.padding_data_left('desktop')
            // flex = this.desktop_flex
            // size = this.desktop_size
          }
          if (transform) styling.transform = transform
          // if (flex) styling.flex = flex + ' !important'
          // if (size) {
          //     styling.width = size[0] + 'px !important'
          //     styling.height = size[1] + 'px !important'
          // }
          // if (padding_top !== undefined) styling.paddingTop = padding_top + 'px !important'
          // if (padding_right !== undefined) styling.paddingRight = padding_right + 'px !important'
          // if (padding_bottom !== undefined) styling.paddingBottom = padding_bottom + 'px !important'
          // if (padding_left !== undefined) styling.paddingLeft = padding_left + 'px !important'
          
          return styling
      },
  }
}